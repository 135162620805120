import React, { useState, ReactNode } from "react";
import { AuthStateProps } from "@kone/ui-library";

import Context, { ContextState } from "./index";
import MENUS from "../constants/menus";
import { NetworkData } from "../schemas";
import { get, patch, API_TYPE, REQUEST_TYPE } from "../misc/fetch";

type ReactProps = {
  children: ReactNode;
};

export default ({ children }: ReactProps) => {
  const [jwtToken, updateJwtToken] = useState("");
  const [applications, setApplications] = useState([]);
  const [selectedMenu, updateSelectedMenu] = useState(
    MENUS.find((mItem) => mItem.url === document.location.pathname)
  );
  const [ongoingNetworks, setOngoingNetworks] = useState<NetworkData[] | []>([]);
  const [unstartedNetworks, setUnstartedNetworks] = useState<NetworkData[] | []>([]);
  const [acquireToken, setAcquireToken] = useState<any>(null);

  const authData = {
    isAuthenticated: false,
  } as AuthStateProps;

  const [authState, updateAuthState] = useState<AuthStateProps>(authData);

  const fetch = async (
    url: string,
    api?: API_TYPE,
    requestType?: REQUEST_TYPE,
    params?: object
  ): Promise<any> => {
    if (requestType === REQUEST_TYPE.PATCH) {
      return patch(url, jwtToken, api, params);
    }
    return get(url, jwtToken, api, params);
  };

  const updateApplications = async () => {
    try {
      const fetchedApplications = await fetch("/applications");

      setApplications(fetchedApplications);
    } catch (error) {
      console.error("updateApplications", error.toJSON());
    }
  };

  const state: ContextState = {
    applications,
    authState,
    fetch,
    jwtToken,
    ongoingNetworks,
    selectedMenu,
    unstartedNetworks,
    acquireToken,
    updateApplications,
    updateAuthState,
    updateJwtToken,
    updateSelectedMenu,
    setOngoingNetworks,
    setUnstartedNetworks,
    setAcquireToken,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
};
