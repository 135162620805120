import { API_TYPE } from "./fetch";
import {
  NetworkDeviationCount,
  InstallationData,
  Assignee,
  InstallationStatus,
  NetworkData,
  AssignmentNetwork,
  NetworkPartner,
} from "../schemas";

type Deviation = {
  blocker: boolean;
};

type FetchedInstallation = {
  questions: [];
  assignees: Assignee[];
  latestCompletedQdQuestionSet: string;
  status: InstallationStatus | null;
  supervisorNumber: string;
  customer: NetworkPartner | null;
  equipmentNumber: string | null;
  totalInstallationHours: string | null;
  startDate: string | null;
  targetDate: string | null;
};

type FetchFunction = (url: string, api?: API_TYPE) => Promise<any>;

const getNetworkDeviationCount = async (
  networkNumber: string,
  fetch: FetchFunction
): Promise<NetworkDeviationCount | null> => {
  try {
    const fetchedDeviations = await fetch(`/deviations?network=${networkNumber}`);
    const deviations: Deviation[] = fetchedDeviations.items;
    const normalDeviations = deviations.filter((deviation) => !deviation.blocker);
    const blockerDeviations = deviations.filter((deviation) => deviation.blocker);

    return {
      normal: normalDeviations.length,
      blockers: blockerDeviations.length,
    };
  } catch (error) {
    return null;
  }
};

const getInstallationData = async (
  networkNumber: string,
  fetch: FetchFunction,
  loggedInEmployeeId: string
): Promise<InstallationData> => {
  const defaultReturn = {
    firstAssignmentDate: "",
    hasQualityForm: null,
    latestCompletedQdSet: "",
    isNotAcceptedByInstaller: null,
    status: null,
    assignees: [],
    customer: null,
    totalInstallationHours: null,
    startDate: null,
    targetDate: null,
    supervisorNumber: null,
    equipmentNumber: null,
  };

  try {
    const fetchedInstallation: FetchedInstallation = await fetch(
      `/installations/${networkNumber}`,
      API_TYPE.APPLICATION
    );

    if (!fetchedInstallation) {
      return defaultReturn;
    }

    const isNetworkSupervisor =
      loggedInEmployeeId === fetchedInstallation.supervisorNumber;
    const hasInstallerAcceptanceStatus =
      fetchedInstallation.status === InstallationStatus.FOR_INSTALLER_ACCEPTANCE;
    const sortedAssignees = [...fetchedInstallation.assignees].sort(
      (firstAssignment, secondAssignment) => {
        return firstAssignment.assignmentStartDate.localeCompare(
          secondAssignment.assignmentStartDate
        );
      }
    );

    return {
      firstAssignmentDate: sortedAssignees[0]?.assignmentStartDate.substr(0, 10),
      hasQualityForm: fetchedInstallation.questions.length > 0,
      latestCompletedQdSet: fetchedInstallation.latestCompletedQdQuestionSet,
      isNotAcceptedByInstaller: isNetworkSupervisor && hasInstallerAcceptanceStatus,
      status: fetchedInstallation.status,
      assignees: fetchedInstallation.assignees,
      customer: fetchedInstallation.customer,
      supervisorNumber: fetchedInstallation.supervisorNumber,
      equipmentNumber: fetchedInstallation.equipmentNumber,
      totalInstallationHours: fetchedInstallation.totalInstallationHours,
      startDate: fetchedInstallation.startDate,
      targetDate: fetchedInstallation.targetDate,
    };
  } catch (error) {
    return defaultReturn;
  }
};

export const getNetworkRelatedData = async (
  network: AssignmentNetwork,
  fetch: FetchFunction,
  loggedInEmployeeId: string
): Promise<NetworkData> => {
  const [deviations, installation] = await Promise.all([
    getNetworkDeviationCount(network.networkNumber, fetch),
    getInstallationData(network.networkNumber, fetch, loggedInEmployeeId),
  ]);
  return { deviations, installation, network };
};
