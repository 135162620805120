export type Application = {
  appleStoreLink?: string;
  description: string;
  externalUrl: string;
  googlePlayLink?: string;
  iconUrl: string;
  id: string;
  internalUrl?: string;
  name: string;
  type: string;
};

export type NetworkPartner = {
  customerNumber: string;

  customerName1?: string;
  customerName2?: string;
  city?: string;
  cityPostalCode?: string;
  countryKey?: string;
  houseNumber?: string;
  partnerFunction?: string;
  street?: string;
};

export type NetworkSite = {
  siteAbsoluteDescription: string;
  siteAbsoluteNumber: number;
  siteAbsoluteStatus: number;
};

//Custom type which return from get employee's network endpoint
export type AssignmentNetwork = {
  networkNumber: string;
  salesOrderDescription: string;
  milestone: string;
  supervisorNumber: string;
};

export type NetworkData = {
  network: AssignmentNetwork;
  installation: InstallationData;
  deviations: NetworkDeviationCount | null;
  /* This is needed in order to know which view to show when
    clicking on the card on to be started page. The reason is
    one 1 person could be both installer and supervisor for 1 network
  */
  showNewInstallationViewAsSupervisor?: boolean;
};

export type Network = {
  networkNumber: string;

  salesOrder?: string;
  currentMilestone?: string;

  applicant?: string;
  businessArea?: string;
  companyCode?: string;
  creationDate?: string;
  deletionFlag?: string;
  equipmentAmount?: number;
  equipmentNumber?: string;
  installationMaterial?: string;
  lastChangeDate?: string;
  networkDescription?: string;
  networkPlant?: string;
  partner?: NetworkPartner[];
  platformCode?: string;
  platformDesc?: string;
  poSubcontract?: string;
  projectType?: string;
  purchaseOrder?: string;
  purchaseRequisition?: string;
  rushCode?: string;
  salesMaterial?: string;
  salesOrderItem?: string;
  salesQuantity?: string;
  salesQuantityUnit?: string;
  site?: NetworkSite[];
  slSalesOrder?: string;
  splanScenario?: string;
  supervisorHRNumber?: string;
  supervisorName?: string;
  supervisorNumber?: string;
  TRBCategory?: string;
  TRBLisType?: string;
  wbsApplicantId?: string;
};

export type Assignment = {
  pk: string;
  sk: string;
  entityType: string;
  activityDifferentiator: string;
  isAssigned: boolean;
  koneResourcePersonalNumber: string;
  koneResourceName: string;
  assignmentStartDate: string;
  assignmentEndDate: string;
  isSubcontractedInstallation: boolean;
  isActivityNotDoubleBooked: boolean;
  userComment: string;
  subcontractCrewName: string;
  subcontractVendorID: string;
  subcontractVendorName: string;
  companyCode: string;
};

export type WorkFlowTask = {
  endObjectAttributeValues: {
    tacoPlannedHours: string;
  };
};

export type InstallationTask = {
  attributeValues: {
    tacoConfigurationId: string;
  };
  structure: {
    workFlowTasks: WorkFlowTask[];
    endObjectAttributeValues: {
      tacoPlannedHours: string;
    };
  };
};

export enum InstallationStatus {
  TO_BE_STARTED = "toBeStarted",
  FOR_INSTALLER_ACCEPTANCE = "forInstallerAcceptance",
  INSTALLER_ACCEPTED = "installerAccepted",
  INSTALLER_REJECTED = "installerRejected",
  FOR_TESTER_ACCEPTANCE = "forTesterAcceptance",
  TESTER_REJECTED = "testerRejected",
  TESTER_ACCEPTED = "testerAccepted",
  FOR_FINAL_INSPECTION = "forFinalInspection",
  FINAL_INSPECTION_DONE = "finalInspectionDone",
  FOR_SEB_ACCEPTANCE = "forSebAcceptance",
  SEB_ACCEPTED = "sebAccepted",
  INSTALLATION_COMPLETE = "installationComplete",
  INSTALLATION_ARCHIVED = "installationArchived",
}

export enum ActivityDifferentiator {
  INST = "INST",
}

export type NetworkDeviationCount = {
  normal: number;
  blockers: number;
};
export type InstallationData = {
  firstAssignmentDate: string;
  hasQualityForm: boolean | null;
  latestCompletedQdSet: string;
  isNotAcceptedByInstaller: boolean | null;
  status: InstallationStatus | null;
  assignees: Assignee[];
  customer: NetworkPartner | null;
  supervisorNumber: string | null;
  equipmentNumber: string | null;
  totalInstallationHours: string | null;
  startDate: string | null;
  targetDate: string | null;
};
export type Assignee = {
  assignmentStartDate: string;
  koneResourcePersonalNumber: string;
  activityDifferentiator: ActivityDifferentiator;
};

export type Employee = {
  legalFirstName: string;
  legalLastName: string;
};
