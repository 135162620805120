import React, {
  lazy,
  PropsWithChildren,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { CssBaseline, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import { HomeOutlined, PlayArrow } from "@material-ui/icons";
import {
  AuthenticatorProps,
  TabMyNetworks,
  theme,
  WithAuthenticator,
  WithAuthenticatorProps,
} from "@kone/ui-library";

import Context from "../../context";
import Loading from "../../components/Loading";
import HeaderBar from "../../components/HeaderBar";
import logo from "../../logo_inverted.svg";
import Widget, { WidgetButtonProps } from "../../components/Widget";
import {
  AssignmentNetwork,
  NetworkData,
  InstallationStatus,
  ActivityDifferentiator,
} from "../../schemas";
import { getNetworkRelatedData } from "../../misc/getNetworkRelatedData";
import { useGet } from "../../hooks/useFetch";
const Applications = lazy(() => import("../Applications"));
const UI_URL = process.env.UI_URL || "";
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: t.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    toBeStartedIcon: {
      display: "flex",
    },
  })
);

const App = (props: PropsWithChildren<WithAuthenticatorProps>): JSX.Element => {
  const {
    authState: { isAuthenticated, user, graph },
    ongoingNetworks,
    unstartedNetworks,
    updateAuthState,
    updateJwtToken,
    setOngoingNetworks,
    setUnstartedNetworks,
    fetch,
    setAcquireToken,
  } = useContext(Context);

  const [widgetData, setWidgetData] = useState<WidgetButtonProps[] | []>([]);

  useEffect(() => {
    const updateLoginData = async (): Promise<void> => {
      try {
        setAcquireToken(() => props.acquireToken);
        const { accessToken } = await props.acquireToken(true);
        updateJwtToken(accessToken);
        await props.updateGraphData(accessToken);
        const authState = await props.getAuthState();
        updateAuthState(authState);
      } catch (error) {
        console.error("updateLoginData", error);
      }
    };

    const sortNetworks = (
      firstNetworkData: NetworkData,
      secondNetworkData: NetworkData
    ) => {
      const { network: firstNetwork } = firstNetworkData;
      const { network: secondNetwork } = secondNetworkData;

      if (firstNetwork.salesOrderDescription > secondNetwork.salesOrderDescription) {
        return 1;
      }
      if (firstNetwork.salesOrderDescription < secondNetwork.salesOrderDescription) {
        return -1;
      }
      if (firstNetwork.networkNumber > secondNetwork.networkNumber) {
        return -1;
      }
      return 1;
    };

    const networkAsInstallerFilter = (networkData: NetworkData) => {
      const employeeId = graph?.profile?.employeeId;

      const isInstaller = networkData.installation.assignees.some(
        (assignee) =>
          assignee.activityDifferentiator === ActivityDifferentiator.INST &&
          assignee.koneResourcePersonalNumber === employeeId
      );
      const isStatusForInstallerAcceptance =
        networkData.installation.status === InstallationStatus.FOR_INSTALLER_ACCEPTANCE;

      return isStatusForInstallerAcceptance && isInstaller;
    };

    const isToBeStartedInstallationForSupervisor = (
      data: {
        network: { supervisorNumber: string };
        installation: { status: InstallationStatus };
      },
      employeeId: string
    ): boolean => {
      return (
        data.network.supervisorNumber === employeeId &&
        (!data.installation.status ||
          [
            InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
            InstallationStatus.TO_BE_STARTED,
          ].includes(data.installation.status))
      );
    };

    const setNetworks = async (): Promise<void> => {
      const employeeId = graph?.profile?.employeeId;

      if (employeeId) {
        const fetchedNetworks: AssignmentNetwork[] | null = await fetch(
          `/employees/${employeeId}/networks`
        );
        const promises: Promise<any>[] = [];

        if (Array.isArray(fetchedNetworks)) {
          for (const network of fetchedNetworks) {
            promises.push(getNetworkRelatedData(network, fetch, employeeId));
          }
          const fetchedNetworksData = await Promise.all(promises);

          setOngoingNetworks(
            fetchedNetworksData
              .filter((data) => !isToBeStartedInstallationForSupervisor(data, employeeId))
              .sort(sortNetworks)
          );
          const toBeStartedNetworkAsSupervisor = fetchedNetworksData
            .filter((data) => isToBeStartedInstallationForSupervisor(data, employeeId))
            .map((data) => ({ ...data, showNewInstallationViewAsSupervisor: true }));

          const toBeStartedNetworkAsInstaller = fetchedNetworksData
            .filter(networkAsInstallerFilter)
            .map((data) => ({ ...data, showNewInstallationViewAsSupervisor: false }));

          setUnstartedNetworks(
            [...toBeStartedNetworkAsSupervisor, ...toBeStartedNetworkAsInstaller].sort(
              sortNetworks
            )
          );
        }
      }
    };
    if (!isAuthenticated) updateLoginData();
    if (isAuthenticated) setNetworks();
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const networksData = {
      icon: <TabMyNetworks />,
      title: "No installations",
    } as WidgetButtonProps;
    const toBeStarted = {
      icon: <PlayArrow className={classes.toBeStartedIcon} />,
      title: "Nothing to be started",
    } as WidgetButtonProps;

    if (ongoingNetworks?.length > 0) {
      networksData.title = "Open installations";
      networksData.action = () => {
        location.assign(`${process.env.IES_APPLICATION_URL}/ongoing`);
      };
    }

    if (unstartedNetworks?.length > 0) {
      toBeStarted.title = "To be started";
      toBeStarted.action = () => {
        location.assign(`${process.env.IES_APPLICATION_URL}/tobestarted`);
      };
    }

    setWidgetData([toBeStarted, networksData]);
  }, [ongoingNetworks, unstartedNetworks]); // eslint-disable-line react-hooks/exhaustive-deps

  const get = useGet();

  const handleTestButtonClick = () => {
    get(`/employees/${graph?.profile?.employeeId}/networks`);
  };

  const classes = useStyles(theme);

  const renderThemedComponent = () => (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <HeaderBar
          handleSidebarToggle={() => undefined}
          isAuthenticated={isAuthenticated}
          onSignOut={props.onSignOut}
          sidemenuWidth={0}
          title="Application launcher"
          titleIcon={HomeOutlined}
          userMenuTitle={user?.userName}
          logo={logo}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Widget title="My installations" data={widgetData} />
          <Suspense fallback={<Loading />}>
            <Applications />
            <Button color="secondary" onClick={handleTestButtonClick}>
              Test me
            </Button>
          </Suspense>
        </main>
      </div>
    </ThemeProvider>
  );

  return isAuthenticated ? renderThemedComponent() : <Loading invisible />;
};

export { App as PureComponent };
export default WithAuthenticator(App, {
  clientId: "c49d3833-ee3c-4bfa-8fa8-d7566b7ec0fd",
  authority: "https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50",
  redirectUri: UI_URL,
  validateAuthority: true,
  postLogoutRedirectUri: UI_URL,
  navigateToLoginRequestUrl: false,
} as AuthenticatorProps);
