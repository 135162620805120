import { useContext } from "react";
import Context from "../context/index";
import { get as getHelperFunction, API_TYPE } from "../misc/fetch";

export const useGet = () => {
  const { acquireToken, jwtToken } = useContext(Context);
  const get = async (url: string, params?: object, apiType?: API_TYPE) => {
    const { accessToken } = await acquireToken(true);
    console.log("old token", jwtToken);
    console.log("here is a new access token", accessToken);
    const data = await getHelperFunction(url, accessToken, apiType, params);
    return data;
  };

  return get;
};
