import React, { PropsWithChildren } from "react";
import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { theme, ArrowRight } from "@kone/ui-library";

import backgroundImage from "./background.jpg";

export interface WidgetButtonProps {
  icon: JSX.Element;
  title: string;
  action?: () => void;
}
export interface WidgetProps {
  data: WidgetButtonProps[];
  title?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    actionIcon: {
      backgroundColor: theme.palette.common.white,
      display: "flex",
      marginLeft: "auto",
      "& svg": {
        fill: theme.palette.text.secondary,
      },
      padding: 3,
    },
    button: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      border: "1px solid grey",
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      width: "100%",
    },
    icon: {
      "& path": {
        fill: theme.palette.common.white,
      },
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    itemsPlaceholder: {
      alignItems: "center",
      display: "flex",
      width: "100%",
    },
    root: {
      padding: theme.spacing(3),
      width: "100%",
      color: theme.palette.primary.contrastText,
      textShadow: `1px 1px ${theme.palette.text.secondary}`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "100% 16%",
      backgroundImage: `url(${backgroundImage})`,
    },
    title: {
      color: theme.palette.common.white,
    },
  })
);

const Widget = (props: PropsWithChildren<WidgetProps>): JSX.Element => {
  const classes = useStyles(theme);

  const renderTitle = () =>
    props.title ? (
      <Typography variant="h6" component="h6">
        {props.title}
      </Typography>
    ) : null;

  return (
    <Typography component="div" className={classes.root} paragraph gutterBottom>
      {renderTitle()}
      {props.data.map((widgetButton: WidgetButtonProps, index: number) => (
        <ButtonBase
          className={classes.button}
          data-testid={`widget__button-${widgetButton.title
            .toLowerCase()
            .replace(" ", "")}`}
          key={`${widgetButton.title}_${index}`}
          onClick={widgetButton.action}
        >
          <Typography component="div" className={classes.itemsPlaceholder}>
            <div className={classes.icon}>{widgetButton.icon}</div>
            <div className={classes.title}>{widgetButton.title}</div>
            {widgetButton.action && (
              <div className={classes.actionIcon}>
                <ArrowRight />
              </div>
            )}
          </Typography>
        </ButtonBase>
      ))}
    </Typography>
  );
};

export default Widget;
